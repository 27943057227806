h3 {
  font-size: 22px !important;
  font-weight: 400;
  padding: 15px 0px;
}

.h4 {
  font-size: 18px !important;
  font-weight: 400 !important;
  padding: 0 15px;
}

h6 {
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 15px 0 0 15px;
}

.subscription-h6 {
  font-weight: 400 !important;
  padding: 0;
}

.modal-body {
  font-size: 15px;
}

.yes-btn,
.no-btn {
  font-size: 14px;
  font-weight: 400;
  padding: 3px 20px;
}

.form-control {
  padding-left: 35px;
  font-size: 15px;
}

.add-specialty-btn,
.add-symptom-btn,
.add-doctor-btn,
.add-patient-btn {
  background-color: #5a5abe;
  color: #fff;
  font-size: 15px;
  padding: 8px 20px;
  border-color: #5a5abe;
}

label {
  display: block;
}

form .fa-user-md,
form .fa-user,
form .fa-envelope,
form .fa-phone {
  color: #5a5abe;
}

.suspended,
.active {
  background-color: green;
  color: #fff;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 2px;
}

.suspended {
  background-color: #5a5abe;
}

form {
  img {
    width: 100%;
  }

  .fa {
    color: #5a5abe;
  }
}

.update-details-btn {
  background-color: #5a5abe;
  color: #fff;
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 2px;
  border-color: #5a5abe;
}

.assign-modules-heading {
  padding-top: 15px;
}

.assign-modules-subheading {
  padding-top: 15px;
  padding-left: 15px;
}

.assign-modules-btn {
  background-color: #5a5abe;
  color: #fff;
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 2px;
  border-color: #5a5abe;
  margin-top: 30px;
}

.doc-file-btn {
  background-color: #5a5abe;
  color: #fff;
  padding: 4px 15px;
  font-size: 13px;
  border-radius: 2px;
  border-color: #5a5abe;
}

.nutrition-update-btn {
  background-color: #5a5abe;
  color: #fff;
  padding: 4px 15px;
  font-size: 13px;
  border-radius: 2px;
  border-color: #5a5abe;
}

#edit-nutrition-img {
  width: 150px;
  height: 150px;
}

input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-image {
  width: 460px;
  border: 1px solid #5a5abe;
  color: #5a5abe;
  background-color: transparent;
  padding: 5px 12px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  margin-top: 25px;
  transition: 0.4s;
  margin: 30px 0;
}

.upload-image:hover {
  cursor: pointer;
  background-color: #5a5abe;
  color: #fff;
}

.update-plan-btn {
  background-color: #5a5abe;
  color: #fff;
  padding: 6px 20px;
  font-size: 13px;
  border-color: #5a5abe;
  margin-top: 20px;
}

.view-member-files {
  background-color: #5a5abe;
  color: #fff !important;
  padding: 4px 15px;
  font-size: 12px;
  border-color: #5a5abe;
  margin-left: 20px;
  text-decoration: none !important;
}

.close {
  outline: none !important;
}

.fa-check-circle {
  font-size: 25px !important;
  color: green;
  margin-bottom: 0 !important;
}

.fa-times-circle {
  font-size: 25px !important;
  color: tomato;
}

audio {
  outline: none !important;
}

._2iA8p44d0WZ-WqRBGcAuEV {
  border-radius: 0 !important;
  padding: 5px 15px !important;

  :focus {
    border-color: #5a5abe !important;
  }
}

.modal-body #managerId {
  position: relative;
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 8px center;
}

.ck.ck-editor__main > .ck-editor__editable {
  min-height: 300px;
  margin-bottom: 20px;
}

.page-link {
  font-size: 16px !important;
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  width: 200px;
}
